import React, { useMemo } from 'react';
import { GetMobileV2DrivingSlip_drivingSlip, GetSeriesDrivingSlips, GetSeriesDrivingSlipsVariables, GetSeriesDrivingSlips_drivingSlips } from 'GQL';
import { useOfflineHandlingQuery } from 'Hooks';
import { loader } from 'graphql.macro';
import { ONE_MINUTE } from 'Store';

export interface ISyncedChoices {
	id: string;
	date: string;
	label: string;
	section: string;
	value: string | null;
	driver: string | undefined;
}

interface ContextProps {
	syncedChoices?: ISyncedChoices[];
	isSyncedFacadePictureUploaded: boolean;
}

export const QuestionnaireSyncContext = React.createContext<ContextProps>({
	syncedChoices: [],
	isSyncedFacadePictureUploaded: false,
});

interface QuestionnaireSyncProviderProps {
	children: React.ReactNode;
	drivingSlip: GetMobileV2DrivingSlip_drivingSlip;
}

const GET_SERIES_DRIVING_SLIPS = loader('src/GQL/DrivingSlips/GetSeriesDrivingSlips.gql');

const QuestionnaireSyncProvider: React.FC<QuestionnaireSyncProviderProps> = ({ children, drivingSlip }): React.ReactElement => {
	const [syncedChoices, setSyncedChoices] = React.useState<ISyncedChoices[]>();
	const [isSyncedFacadePictureUploaded, setIsSyncedFacadePictureUploaded] = React.useState<boolean>(false);

	const { data: relatedDrivingSlips } = useOfflineHandlingQuery<GetSeriesDrivingSlips, GetSeriesDrivingSlipsVariables>(GET_SERIES_DRIVING_SLIPS, {
		variables: {
			seriesId: drivingSlip.series,
			excludeTotalCount: true,
		},
		skip: drivingSlip === undefined,
		pollInterval: ONE_MINUTE * 5,
	});

	React.useEffect(() => {
		const getDrivingSlipsFacadePicture = () => {
			for (const relatedDrivingSlip of relatedDrivingSlips?.drivingSlips ?? []) {
				if (relatedDrivingSlip.facadePictureTaken) {
					setIsSyncedFacadePictureUploaded(true);
					return;
				}
			}

			setIsSyncedFacadePictureUploaded(false);
		};

		const getDrivingSlipsQuestionnaires = () => {
			const syncedChoices: ISyncedChoices[] = [];

			const dsArray = relatedDrivingSlips?.drivingSlips?.filter((ds: GetSeriesDrivingSlips_drivingSlips) => ds.id !== drivingSlip.id && ds.series === drivingSlip.series);

			dsArray?.forEach((ds: GetSeriesDrivingSlips_drivingSlips) => {
				ds.questionnaire?.template.sections.forEach(section => {
					section.choices.forEach(choice => {
						syncedChoices.push({
							date: ds.createdAt,
							driver: ds.driver?.name,
							id: choice.id,
							value: choice.value,
							section: section.label,
							label: choice.label,
						});
					});
				});
			});

			setSyncedChoices(syncedChoices);
		};

		getDrivingSlipsFacadePicture();
		getDrivingSlipsQuestionnaires();
	}, [drivingSlip.id, drivingSlip.series, relatedDrivingSlips]);

	const context: ContextProps = useMemo(
		() => ({
			syncedChoices,
			isSyncedFacadePictureUploaded: isSyncedFacadePictureUploaded,
		}),
		[isSyncedFacadePictureUploaded, syncedChoices],
	);

	return <QuestionnaireSyncContext.Provider value={context}>{children}</QuestionnaireSyncContext.Provider>;
};

export default QuestionnaireSyncProvider;
