import React from 'react';
import { DateTime } from 'luxon';
import { SupplementType, TimeTrackingEntry } from '../GraphQL/indexV2';

interface TimeSlotOption {
    key: string;
    value: string;
    isBlocked: boolean;
}

interface StartEnd {
    startTime: string;
    endTime: string;
    startDate: string;
    endDate: string;
    registerDate: string;
}

const generatetimeSlotOptions = (startEnd: StartEnd) => {
    // Find the maximum end time based on the first entry after the selected startTime
    const { startTime, endTime, startDate, endDate, registerDate = new Date() } = startEnd;

    const ogStart = DateTime.fromFormat(`${registerDate}-${'00:00'}`, 'yyyy-MM-dd-HH:mm');
    const start = DateTime.fromFormat(`${startDate}-${startTime}`, 'yyyy-MM-dd-HH:mm');
    const end = DateTime.fromFormat(`${endDate}-${endTime}`, 'yyyy-MM-dd-HH:mm');

    // Check if end date is before start date and adjust accordingly
    // This is to handle the case where the end time is after midnight and might be next month
    let adjustedEnd = end;
    if (end < start) {
        adjustedEnd = end.plus({ months: 1 });
    }

    const pushDiff = start.diff(ogStart, 'minutes').minutes;
    const diff = adjustedEnd.diff(start, 'minutes').minutes;
    const slots = diff / 15;
    const pushSlots = pushDiff / 15;
    const totalSlots = slots + pushSlots;

    // Generate time slot options from 00:00 am to 00:00 am the next day
    const todayOptions: TimeSlotOption[] = [];
    const nextDayOptions: TimeSlotOption[] = [];

    for (let i = pushSlots; i < totalSlots; i++) {
        const totalMinutes = i * 15;
        const hours = Math.floor(totalMinutes / 60) % 24;
        const minutes = totalMinutes % 60;
        const isNextDay = totalMinutes >= 24 * 60;
        const key = `${hours < 10 ? '0' : ''}${hours}${minutes === 0 ? '00' : minutes}`;
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes === 0 ? '00' : minutes}`;

        const option = {
            key: `${isNextDay ? 'next-' : ''}${key}`,
            value: formattedTime,
            isBlocked: false,
        };

        if (isNextDay) {
            nextDayOptions.push(option);
        } else {
            todayOptions.push(option);
        }
    }

    return {
        nextDayOptions,
        todayOptions,
    };
};

export const generatetimeSlotHTMLOptions = (startDate: string, timeTrackingEntries: TimeTrackingEntry[], editingId: string | undefined, editMode: boolean) => {
    const endDate = DateTime.fromFormat(startDate, 'yyyy-MM-dd').plus({ days: 1 }).toISODate();

    if(startDate === '') return;

    // Find the maximum end time based on the first entry after the selected startTime
    const { nextDayOptions: next, todayOptions: today } = generatetimeSlotOptions({
        endDate,
        startDate,
        endTime: '23:45',
        startTime: '00:00',
        registerDate: startDate,
    });

    const timeTrackingTimeSlots = timeTrackingEntries
        .filter(tt => (typeof editingId !== 'undefined' ? tt.id !== editingId : true))
        // Removed all entries with stand-alone supplements
        .filter(e => !e.addonLines.some(a => a.supplementType === SupplementType.StandAlone))
        .flatMap(entry => {
            const slots = generatetimeSlotOptions({
                endDate: entry.endDate,
                startDate: entry.startDate,
                endTime: entry.endTime,
                startTime: entry.startTime,
                registerDate: startDate,
            });

            return [...slots.nextDayOptions.slice(editMode ? 1 : 0), ...slots.todayOptions.slice(editMode ? 1 : 0)];
        })
        .sort((a, b) => a.key.localeCompare(b.key));

    const lastSlot = timeTrackingTimeSlots.slice(-1)[0];

    let allOptions: TimeSlotOption[] = [...today, ...next].sort((a, b) => a.key.localeCompare(b.key));

    const lastSlotIndex = allOptions.findIndex(option => lastSlot?.key === option.key);

    allOptions = allOptions.map((option, i) => {
        let isBlocked = timeTrackingTimeSlots.some(timeSlot => timeSlot.key === option.key);
        if (!editMode) {
            isBlocked = lastSlotIndex >= i;
        }

        return {
            ...option,
            isBlocked,
        };
    });

    if (editMode && editingId) {
        const timeTrackingTimeSlots = timeTrackingEntries
            .filter(tt => tt.id === editingId)
            // Removed all entries with stand-alone supplements
            .filter(e => !e.addonLines.some(a => a.supplementType === SupplementType.StandAlone))
            .flatMap(entry => {
                const slots = generatetimeSlotOptions({
                    endDate: entry.endDate,
                    startDate: entry.startDate,
                    endTime: entry.endTime,
                    startTime: entry.startTime,
                    registerDate: startDate,
                });

                return [...slots.nextDayOptions.slice(editMode ? 1 : 0), ...slots.todayOptions.slice(editMode ? 1 : 0)];
            })
            .sort((a, b) => a.key.localeCompare(b.key));

        if (timeTrackingTimeSlots.length > 0) {
            const lastSlot = timeTrackingTimeSlots.slice(-1)[0];
            const firstSlot = timeTrackingTimeSlots[0];
            const firstHalf = allOptions.slice(0, allOptions.findIndex(option => option.key === firstSlot.key));
            const secondHalf = allOptions.slice(allOptions.findIndex(option => option.key === lastSlot.key) + 1);
            const fistHalfLastBlockedKey = firstHalf.filter(option => option.isBlocked).slice(-1)[0]?.key;
            const secondHalfFirstBlockedKey = secondHalf.find(option => option.isBlocked)?.key;

            const firstHalfBlockedIndex = fistHalfLastBlockedKey ? allOptions.findIndex(option => option.key === fistHalfLastBlockedKey) : -1;
            const secondHalfBlockedIndex = secondHalfFirstBlockedKey ? allOptions.findIndex(option => option.key === secondHalfFirstBlockedKey) : 9999;
            allOptions = allOptions.map((option, i) => {
                const isBlocked = i <= firstHalfBlockedIndex || i >= secondHalfBlockedIndex;

                return {
                    ...option,
                    isBlocked,
                };
            });
        }
    }

    const todayWithBlocked = allOptions.filter(option => !option.key.startsWith('next-'));
    const nextDayWithBlocked = allOptions.filter(option => option.key.startsWith('next-'));

    const currentDayLabel = DateTime.fromFormat(startDate ?? '', 'yyyy-MM-dd').toLocaleString({
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const currentDayValue = DateTime.fromFormat(startDate ?? '', 'yyyy-MM-dd').toISODate();
    const nextDayLabel = DateTime.fromFormat(startDate ?? '', 'yyyy-MM-dd')
        .plus({ days: 1 })
        .toLocaleString({
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    const nextDayValue = DateTime.fromFormat(startDate ?? '', 'yyyy-MM-dd')
        .plus({ days: 1 })
        .toISODate();

    return (
        <>
            <optgroup label={currentDayLabel}>
                {todayWithBlocked.map(option => (
                    <option key={option.key} value={option.value + 'D' + currentDayValue} disabled={option.isBlocked} data-next-day="false" data-test={option.key}>
                        {option.value}
                    </option>
                ))}
            </optgroup>
            <optgroup label={nextDayLabel}>
                {nextDayWithBlocked.map(option => (
                    <option key={option.key} value={option.value + 'D' + nextDayValue} disabled={option.isBlocked} data-next-day="true" data-test={option.key}>
                        {option.value}
                    </option>
                ))}
            </optgroup>
        </>
    );
};