import React from 'react';
import Modal from '../Components/Modal';
import Button from '../Components/Button';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Exact, GetTimeTrackingDayLatestByUserQuery, GetTimeTrackingsDayForUserQuery, Scalars, TimeTrackingDay, TimeTrackingDayInputType, UserBasisTimeQuery } from '../GraphQL/indexV2';
import { DateTime } from 'luxon';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { funktionaer } from './TimeRegHelpers';

interface Props {
    employeeSalaryType: string;
    showOvertimeWarning: boolean;
    setShowOvertimeWarning: (value: React.SetStateAction<boolean>) => void;
    basisTimeRaw: UserBasisTimeQuery | undefined;
    setAllowOvertime: (value: React.SetStateAction<boolean>) => void;
    editMode: boolean;
    remoteSubmit: () => void;
    createTimeTrackingLoading: boolean;
    showCloseDayModal: boolean;
    setShowCloseDayModal: (value: React.SetStateAction<boolean>) => void;
    userRegisteredHours: number;
    allowOvertime: boolean;
    timeTrackingsDayDataRaw: TimeTrackingDay;
    selectedDate: {
        set: React.Dispatch<React.SetStateAction<string>>;
        value: string;
    }
    accOvetimeHours: number;
    closeDay: (timeTracking: TimeTrackingDayInputType) => Promise<void>;
    dayEntry: {
        id: string;
        user: string;
        date: string;
        isPartOfRange: boolean;
        rangeEndDate: string | undefined;
    };
    closeRejectModal: (() => void) | undefined;
    resetForm: () => void;
    setValue: (name: string, value: unknown, config?: Partial<{
        shouldValidate: boolean;
        shouldDirty: boolean;
    }> | undefined) => void;
    refetchLatestDay: (variables?: Partial<Exact<{
        user: Scalars['String']['input'];
    }>> | undefined) => Promise<ApolloQueryResult<GetTimeTrackingDayLatestByUserQuery>>;
    latestTimeregCallback: (data: GetTimeTrackingDayLatestByUserQuery) => void;
    rejectMode: boolean;
    refetchRejected: (() => void) | undefined;
    refetchTimeTrackingsDay: () => Promise<ApolloQueryResult<GetTimeTrackingsDayForUserQuery>>;
    submitLoading: boolean;
    closeDayCannotBeOverruled: boolean;
}

const TimeRegistrationModals: React.FC<Props> = ({
    employeeSalaryType,
    showOvertimeWarning,
    setShowOvertimeWarning,
    basisTimeRaw,
    setAllowOvertime,
    editMode,
    remoteSubmit,
    createTimeTrackingLoading,
    showCloseDayModal,
    setShowCloseDayModal,
    userRegisteredHours,
    allowOvertime,
    timeTrackingsDayDataRaw,
    selectedDate,
    accOvetimeHours,
    closeDay,
    dayEntry,
    closeRejectModal,
    resetForm,
    setValue,
    refetchLatestDay,
    latestTimeregCallback,
    rejectMode,
    refetchRejected,
    refetchTimeTrackingsDay,
    submitLoading,
    closeDayCannotBeOverruled,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                title="timeRegistration.registrationExceedsBasisTime"
                visible={showOvertimeWarning}
                nestedModal
                close={() => setShowOvertimeWarning(false)}
                body={
                    <div>
                        <p>
                            {t('timeRegistration.registratingWillExceedBasisTime')} <strong className="mr-1">{basisTimeRaw?.userBasisTime ?? 0}</strong>
                            <span className="mr-1 lowercase">{t('timeRegistration.totalHours')}.</span>
                            <Trans t={t} i18nKey="timeRegistration.overtimeSuppWillBeAdded" components={[<strong />]} />
                        </p>

                        <p className="italic">{t('timeRegistration.checkTimeDraft')}</p>

                        <div className="mt-2 flex justify-between">
                            <Button
                                secondary
                                text="common.cancel"
                                onClick={() => {
                                    setShowOvertimeWarning(false);
                                    setAllowOvertime(false);
                                }}
                            />
                            <Button
                                primary
                                text={editMode ? 'common.update' : 'common.register'}
                                onClick={() => {
                                    setAllowOvertime(true);
                                    setTimeout(() => {
                                        remoteSubmit();
                                    }, 0);
                                }}
                                loading={createTimeTrackingLoading}
                            />
                        </div>
                    </div>
                }
            />

            <Modal
                title="timeRegistration.closeDay"
                visible={showCloseDayModal}
                nestedModal
                close={() => setShowCloseDayModal(false)}
                body={
                    <div>
                        {(userRegisteredHours ?? 0) < (basisTimeRaw?.userBasisTime ?? 0) ? (
                            <div>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="timeRegistration.hoursRegisteredOnDay"
                                        values={{
                                            hours: userRegisteredHours,
                                        }}
                                        components={[<strong />]}
                                    />
                                </p>

                                <div className={classNames({ invisible: allowOvertime })}>
                                    <p>
                                        <Trans
                                            t={t}
                                            i18nKey="timeRegistration.notEnoughHoursRegistered"
                                            values={{
                                                hours: basisTimeRaw?.userBasisTime ?? 0,
                                            }}
                                            components={[<strong />]}
                                        />
                                    </p>
                                    <p className="mb-4 italic">{t('timeRegistration.useFlexHours')}</p>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Trans
                                    t={t}
                                    i18nKey="timeRegistration.wantToCloseDay"
                                    values={{
                                        date: timeTrackingsDayDataRaw?.isPartOfRange
                                            ? DateTime.fromISO(timeTrackingsDayDataRaw?.rangeStartDate ?? '').toFormat('dd/MM/yyyy')
                                            : DateTime.fromISO(selectedDate.value).toFormat('dd/MM/yyyy'),
                                    }}
                                />
                                {timeTrackingsDayDataRaw?.isPartOfRange && <span>{` - ${DateTime.fromISO(timeTrackingsDayDataRaw?.rangeEndDate ?? '').toFormat('dd/MM/yyyy')}`}</span>}?
                            </div>
                        )}

                        {accOvetimeHours > 0 && (
                            <>
                                {accOvetimeHours === 1 ? (
                                    <Trans
                                        t={t}
                                        i18nKey="timeRegistration.totalOvertime_one"
                                        values={{
                                            hours: accOvetimeHours,
                                        }}
                                        components={[<strong />]}
                                    />
                                ) : (
                                    <Trans
                                        t={t}
                                        i18nKey="timeRegistration.totalOvertime_many"
                                        values={{
                                            hours: accOvetimeHours,
                                        }}
                                        components={[<strong />]}
                                    />
                                )}
                            </>
                        )}

                        <div className="mt-2 flex justify-between">
                            <Button secondary text="common.cancel" onClick={() => setShowCloseDayModal(false)} />
                            <Button
                                primary
                                text="timeRegistration.closeDay"
                                onClick={async () => {
                                    // If funktionær and no hours registered
                                    const isFunk = funktionaer.includes(employeeSalaryType);
                                    const currentDay = new Date(selectedDate.value).getDay();
                                    // currentDay is monday, tuesday, wednesday, thursday or friday
                                    if (isFunk && currentDay >= 1 && currentDay <= 5 && userRegisteredHours === 0) {
                                        const dialog = window.confirm(t('timeRegistration.noHoursRegisteredFunk'));
                                        if (!dialog) {
                                            return;
                                        }
                                    }
                                    await closeDay({
                                        date: dayEntry.date,
                                        user: dayEntry.user,
                                        isPartOfRange: dayEntry.isPartOfRange,
                                    });
                                    setShowCloseDayModal(false);
                                    closeRejectModal && closeRejectModal();
                                    resetForm();
                                    const date = DateTime.fromFormat(dayEntry.isPartOfRange ? dayEntry.rangeEndDate ?? '' : selectedDate.value ?? '', 'yyyy-MM-dd')
                                        .plus({ days: 1 })
                                        .toISODate();
                                    selectedDate.set(date);
                                    setValue('date', date);
                                    const latestDayData = await refetchLatestDay();
                                    if (typeof latestDayData !== 'undefined') {
                                        latestTimeregCallback(latestDayData.data);
                                    }
                                    rejectMode && refetchRejected && refetchRejected();
                                    await refetchTimeTrackingsDay();
                                }}
                                disabled={submitLoading || closeDayCannotBeOverruled}
                                loading={submitLoading}
                            />
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default TimeRegistrationModals;